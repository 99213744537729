.dispensary-container {
  padding: 20px;
  padding-top: 100px;
  max-width: 800px;
  margin: 40px auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.products-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.dispensary-title {
  font-size: 28px;
  font-weight: 550;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.category-container {
  margin-bottom: 10px;
  /* border-bottom: 2px solid black; */
}

.category-card {
  cursor: pointer;
  border-radius: 18px 18px 18px 18px;
  border: none;
  background-color: white;
  color: #111;
  min-width: 100px;
  font-weight: 500;
  transition: 0.22s ease-in;
}

.category-card:hover {
  /* scale: transform(1.1); */
  color: #f1f1f1;
  background-color: #ff6d6d;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.category-card.active {
  background-color: #ff6d6d;
  color: #fff;
}

.category-name {
  font-size: 13px;
  text-align: center;
}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.product-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.product-name {
  margin: 5px 0;
  text-align: center;
  height: 17px;
}
.product-thc {
   margin: 5px 0;
   margin-top: 10px;
  text-align: center;
}
.product-price {
  font-weight: 500;
  text-align: center;
  margin-left: 60px;
}

.product-image {
  width: 100px;
  height: 100px;
  border-radius: 6px;
}

.subtitle {
  font-size: 22px;
  font-weight: 600;
  color: #FF6D6D;
  margin-bottom: 15px;
  text-align: left;
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 10px;
}

.popup {
  font-weight: 400;
  font-size: 13px;
  position: fixed;
  top: 5%;
  right: 5%;
  background-color: #f1f1f1;
  color: #111;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999999;
  opacity: 1;
  transform: translateY(0);
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.view-cart-button {
  font-weight: 500;
  color: white;
  background-color: #111;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px 20px;
}

@media screen and (max-width: 768px) {
  .products-container {
    grid-template-columns: 1fr 1fr;
  } 
}

@media screen and (max-width: 480px) {
  .products-container {
    grid-template-columns: 1fr;
  }
}