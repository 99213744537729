.about-container {
    margin: auto;
    max-width: 800px;
    padding: 20px;
}

.about-header h1 {
    padding-top: 130px;
    text-align: center;
    color: #000; /* Adjust the color as per your brand */
}

.about-content h2 {
    color: #FF6D6D;
    margin-top: 20px;
}

.about-content p {
    color: #666;
    line-height: 1.6;
}

/* Additional styling as needed */
