.order-history-container {
    padding: 20px;
    padding-top: 100px;
    max-width: 800px;
    margin: 40px auto;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .order-history-header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .order-item {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .item-details {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .item-image {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    margin-right: 15px;
  }
  
  .item-description {
    font-size: 16px;
  }
  