.cart-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .cart-header, .section-header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 125px;
  }
  
  .dispensary-name {
    font-size: 18px;
    font-style: italic;
    color: #555;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .item-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
  }
  
  .item-details {
    display: flex;
    align-items: center;
    flex: 1;
  }
  
  .item-image {
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }
  
  .item-text {
    margin-left: 15px;
  }
  
  .item-name {
    font-weight: bold;
    color: #333;
  }
  
  .remove-button {
    background-color: #ff6d6d;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .remove-button:hover {
    background-color: #e55e5e;
  }
  
  .promo-code-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .promo-input {
    flex: 1;
    padding: 8px 12px;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
  }
  
  .promo-button {
    background-color: #ff6d6d;
    color: white;
    border: none;
    border-radius: 12px;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .continue-button {
    font-size: 14px;
    border-radius: 12px;
    width: 100%;
    background-color: #111;
    color: white;
    border: none;
    padding: 12px;
    cursor: pointer;
    margin-top: 20px;
  }

  .quantity-controls {
    display: flex;
  }

  .adjust-quantity-button {
    width: 25px;
    height: 25px;
    background: #f1f1f1;
    color: #111;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: none;
    border-radius: 6px;
  }

   .continue-button:hover {
    background-color: #ff6d6d;
    /* font-weight: 500; */
   
  }
  .product-quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  /* Add any additional specific styles for responsiveness or other elements */
  