.address-container {
  /* padding-top: 100px !important; */
    padding: 20px;
    max-width: 600px;
    margin: 40px auto;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .address-header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    /* margin-bottom: 30px; */
  }
  
  .address-input {
    border: 1px solid #ccc;
    padding: 15px;
    margin-bottom: 20px;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    border-radius: 8px;
    resize: none; /* Optional: if you want to prevent resizing */
  }
  
  .address-button {
    background-color: #ff6d6d;
    padding: 15px;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    width: 100%;
    border: none;
    transition: background-color 0.3s;
  }
  
  .address-button:hover {
    background-color: #ff6d6d; 
  }
  