.profile-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    padding-top: 130px;
  }
  
  .user-name {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: right;
    margin-bottom: 30px;
  }
  
  .profile-section {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .section-title {
    font-size: 20px;
    font-weight: bold;
    color: #444;
    margin-bottom: 15px;
  }
  
  .profile-item {
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    color: #0066cc;
    transition: color 0.3s;
  }
  
  .profile-item:hover {
    color: #ff6d6d;
  }
  
  .logout-button {
    text-align: center;
    margin-top: 30px;
  }
  
  .logout-button button {
    background-color: #ff6d6d;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .logout-button button:hover {
    background-color: #e55e5e;
  }
  
  /* Add any additional specific styles for responsiveness or other elements */
  