@font-face {
  font-family: "Poppins";   /*Can be any text*/
  src: local("Poppins-Regular"), url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}

.font-face-poppins {
  font-family: "Poppins";   /*Can be any text*/
  /* src: local("Poppins-Regular"), url("./assets/fonts/Poppins-Regular.ttf") format("truetype"); */
}

/* body {
  font-family: 'Poppins', sans-serif;
} */
html, body {touch-action: none;}

.App {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
li,
/* a {
	font-family: 'Open Sans', sans-serif;
}

p {
	font-family: 'Roboto', sans-serif;
} */
header{
    font-family: 'Poppins', sans-serif;
}
h5,
h3 {
	font-weight: 600;
}

.btn-rounded {
	border-radius: 25px;
	color: white;
	background-color: #ff6d6d;
	border: none;
	padding-right: 20px;
	padding-left: 20px;
}

/* a:hover {
	text-decoration: none;
	color: #ff6d6d;
} */

* {
	transition: .2s all ease-in-out;
}

html {
	scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.app-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}