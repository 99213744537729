/* Hamburger Icon Style */
.hamburger-icon {
  display: none; /* Hide by default */
  cursor: pointer;
}

/* Mobile Navigation Links */
.mobile-nav-links {
  display: none; /* Hide by default */
  flex-direction: column; /* Stack links vertically */
  align-items: center; /* Center-align items */
  margin-top: 10px; /* Adjust as needed */
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
 .dropdown-select:focus, .dropdown-select .select__control input:focus {
     font-size: 1.8vh;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .hamburger-icon {
    display: block; /* Show the hamburger icon on mobile devices */
  }

  .mobile-nav-links {
    display: flex; /* Show the links when menu is open */
  }

  .link-container, .icon-container {
    display: none; /* Optionally hide other navigation elements on mobile */
  }
}
.bottom-nav-container {
  position: relative;
  overflow-x: hidden; /* Add this to prevent horizontal scrolling */
}
.search-bar-container {
  background-color: #f1f1f1;
  border-radius: 33px;
  margin-left: 10px;
  position: relative; /* Needed for absolute positioning of children */
  width: 100%; /* Adjust as needed */
  /* Other styles... */
}
/* 
.nav-link {
 font-size: 13px;
} */
.product-search {
  border-radius: 33px;
  padding-left: 10px;
  width: 100%; /* Adjust width as needed */
  /* Other styles... */
}

.search-results-dropdown {
  position: absolute;
  top: 100%; /* Start the dropdown right below the search bar */
  left: 0;
  width: 100%; /* Match the width with the search bar */
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  z-index: 10; /* Ensures it's above other content but not covering the search bar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visibility */
  max-height: 300px; /* Limit the height of the dropdown */
  overflow-y: auto; /* Allows scrolling within the dropdown */
  padding-left: 20px;
  border-radius: 8px;
}

.search-result-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  font-size: 12.5px;
}

.search-result-item:hover {
   transition: 0.2s ease-in;
  background-color: #f1f1f1;
}


.bottom-nav-bar {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  max-width: 100%;
  width: 100%;
 /* min-width: 90%; */
  position: fixed;
  top: 0;
  left: 0;
  /* backdrop-filter: blur(14px) saturate(180%);
  -webkit-backdrop-filter: blur(14px) saturate(180%); */
  border: 1px solid rgba(209, 213, 219, 0.3);
  z-index: 1000;
}

.logo-link .logo-image {
  height: 54px; /* Smaller logo for mobile */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.link-container {
  display: flex;
  gap: 15px; /* Reduced gap for mobile */
  margin: 10px auto;
  align-items: center;
  justify-content: center;
}

.nav-link{
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  text-decoration: none;
  font-size: 12px; /* Smaller font size for mobile */
  font-weight: 500;
}

.nav-link:hover, user-icon:hover, .cart-icon:hover{
  transition: 0.2 ease;
   transform: translateY(-1px);
    color: #333;
  /* transform: scale(1.05); */

}

.icon-container {
  display: flex;
  padding-right: 15px; /* Less padding for mobile */
  margin: 10px;
}

.search-address-container {
  left: -1px;
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  top: 56px; /* Adjust based on your navbar height */
  width: 100%;
  max-width: 100%;
  background: #f3f3f3;
  z-index: 999; /* Ensure it's below the navbar */
  height: 40px;
}

/* Search Bar Style */
.search-bar {
  display: flex;
  align-items: center;
}

.product-search {
  width: 100%;
  border: none;
  background: none;
  opacity: 0.7;
  width: 100%; 
  flex-grow: 1;
}

/* Address Bar Style */
.search-bar-container, .address-bar {
  margin-right: 20px;
  background-color: #f3f3f3; 
  border-radius: 33px; 
  width: 100%; 
  display: flex; 
  flex-grow: 1; /* Allows the address bar to grow but within limits */
  height: 100%;
}

.input-address {
  flex-grow: 1; /* Allows the input field to take up available space */
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  padding-left: 2px;
  border: none !important;
  border-radius: 33px;
  border-style: none;
  background-color: #f3f3f3;
  outline: none;
  font-size: 11px;
}

.input-address, .product-search {
  font-size: 12px; /* Prevent zoom on mobile */
  /* Other styles */
}

.input-address:focus, .product-search:focus {
  outline: none;
}

.search-icon {
  margin: 10px;
  color: #111; /* Adjust color as needed */
  font-size: 15px; /* Adjust size as needed */
}
.location-icon {
  margin: 8px;
  color: #111; /* Adjust color as needed */
  font-size: 15px; /* Adjust size as needed */
}

/* button < #location-button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
} */

.current-address {
  margin-left: 10px;
  font-size: 12px;
  color: #555;
}

.icon-link {
  color: black;
  padding: 0 5px;
  font-size: 16px; /* Smaller icons for mobile */
}

/* PREVIOUSLY USED 
.cart-bubble {
  background-color: #FF6D6D;
  color: white;
  border-radius: 50%;
  padding: 1px 6px;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: -10px;
  left: -5px;
} */
/* GPT 4o */
.cart-bubble {
   position: relative;
  top: -10px;
left: -5px;
  background-color: #FF6D6D;
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  /* top: -8px;
  right: -8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cart-bubble:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.user-icon {
  font-size: 20px;
  /* Additional styles if needed */
}

@media (min-width: 768px) {
  .logo-link .logo-image {
    height: 50px; /* Adjust for tablet */
  }

  .link-container {
    gap: 15px; /* Increased gap for tablet */
  }

  .nav-link {
    font-size: 16px; /* Adjust font size for tablet */
  }

  .icon-link {
    font-size: 18px; /* Larger icons for tablet */
  }

  .cart-icon {
    font-size: 22px;
  }

.user-icon {
  font-size: 20px;
  /* Additional styles if needed */
}

  .search-address-container {
    flex-direction: row;
    top: 59px; /* Adjust for smaller screens */
  }

  .search-bar, .address-bar input {
    width: 50%;

  }

  .address-bar {
    flex-direction: row;

  }
}

@media (min-width: 992px) {
  .logo-link .logo-image {
    height: 55px; /* Adjust for desktop */
  }

  .link-container {
    gap: 20px; /* Increased gap for desktop */
  }

  .nav-link {
    font-size: 16px; /* Adjust font size for desktop */
  }

  .icon-link {
    font-size: 20px; /* Larger icons for desktop */
  }

  .cart-icon, .user-icon {
    font-size: 22px;
  }
}

@media (min-width: 1200px) {
  .logo-link .logo-image {
    height: 70zpx; /* Adjust for large desktop */
  }

  .bottom-nav-bar {
    width: 100%;
  }
  .search-address-container {
    width: 50%;
    background: #fff;
    /* Override the specific styles you don't want on larger screens */
    left: auto; /* Reset or change as needed */
    top: auto; /* Reset or change as needed */
    position: static; /* Change the position as needed */
    /* ... other overrides or resets ... */
  }
  /* ... further adjustments if needed ... */
}

  @media (max-width: 480px) {

    .cart-icon {
      font-size: 18px;
    }
    .cart-bubble {
      border-radius: 50%;
      padding: 1px 6.5px;
    }
      .search-bar, .address-bar input {
    width: 50%;

  }
  .nav-link {
    margin-top: 5px;
    font-size: 14px;
  
  }
  .logo-image {
    height: 20px;
  }
  }

