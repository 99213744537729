.footer {
    background-color: #111; /* Dark background for contrast */
    color: white;
    font-family: 'Poppins', sans-serif;
    padding: 20px;
    text-align: center;
    overflow: auto;
    margin-top: 10px;
  }
  
  .footer-upper, .footer-lower {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
  }
  
  .footer-logo .logo {
    width: 75px; /* Adjust size as needed */
  }
  
  .footer-links {
    display: flex;
    font-size: 1rem;
  }
  
  .footer-link {
    color: #ff6d6d; /* Accent color */
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.13s;
  }
  
  .footer-link:hover {
    color: #ffa6a6; /* Hover effect */
  }

  .email-link, .number-link {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 10px;
  }

  .social-media .icon {
    font-size: 24px;
    color: white;
    margin: 0 10px;
    transition: transform 0.2s ease-in;
  }
  
  .social-media .icon:hover {
    /* transform: scale(1.05); Icon hover effect */
    transform: translateY(-2px);
  }
  
  .contact-info {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .footer-bottom {
    font-size: 11px;
    margin-top: 20px;
    border-top: 1px solid #444; /* Subtle separator */
    padding-top: 10px;
  }
  
  @media (max-width: 768px) {
    .footer-upper, .footer-lower {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-links, .social-media {
      flex-direction: column;
      align-items: center;
      margin: 10px 0;
    }
  
    .footer-link, .social-media .icon {
      margin: 5px 0;
    }
  
    .contact-info {
      margin: 5px 0;
    }
  
    .footer-logo .logo {
      width: 90; /* Slightly smaller logo for tablet */
    }
  
    .social-media .icon {
      font-size: 20px; /* Slightly smaller icons for better spacing */
      margin: 10px;
    }
  }

  @media (max-width: 480px) {
    .footer-logo .logo {
      width: 85; /* Further reduced size for mobile */
    }
  
    .social-media .icon {
      font-size: 18px; /* Further reduced icon size for mobile */
    }
  
    .footer-link {
      font-size: 14px; /* Adjust font size for better readability */
    }
  
    .footer-bottom {
      font-size: 12px; /* Smaller font size for the bottom text */
    }
  
    .contact-info p {
      font-size: 14px; /* Adjust contact info font size for readability */
    }
  }
  