.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 300px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 7px;
}

.imageContainer {
  width: 90%;
  display: flex;
  justify-content: center; /* Center the image horizontally */
}

.productImage {
  width: 80%;
  max-height: 200px;
  border-radius: 10px;
  max-width: 300px;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.productName, .thcPercentage, .dispensaryName {
  font-size: 16px;
  text-align: center;
  color: #333;
  margin-bottom: 10px;
}

.thcPercentage, .dispensaryName {
  color: #666;
}

.addToCartButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF6D6D;
  color: white;
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-weight: bold;
}

.addToCartButton:hover {
  background-color: #e56262;
}

.buttonText {
  font-size: 14px;
  margin-left: 10px;
}

.cartIcon {
  font-size: 15px;
}
@media (max-width: 375px) {
 
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 300px;
  margin: 20px auto;
  background-color: #fff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 7px;
}

.imageContainer {
  width: 90%;
  display: flex;
  /* justify-content: left; */
}

.productImage {
  width: 80%;
  max-height: 200px;
  border-radius: 10px;
  /* item-align: left; */
  max-width: 300px;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.productName {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

.thcPercentage {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
}

.dispensaryName {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.stepper {
  margin-bottom: 20px;
}

.addToCartButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF6D6D;
  color: white;
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-weight: bold;
}
.buttonText {
  font-size: 14px;
  margin-left: 10px;
}

.addToCartButton:hover {
  background-color: ##e56262;
}

.buttonText {
  margin-left: 10px;
}

.cartIcon {
  font-size: 15px;
}


.productImage {

  max-height: 200px;
  border-radius: 10px;
  item-align: center;
  width: 90%;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-right: 32px;
}

.productName {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

.thcPercentage {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
}

.dispensaryName {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.stepper {
  margin-bottom: 20px;
}

.addToCartButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF6D6D;
  color: white;
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-weight: bold;
}
.buttonText {
  font-size: 14px;
  margin-left: 10px;
}

.addToCartButton:hover {
  background-color: ##e56262;
}

.buttonText {
  margin-left: 10px;
}

.cartIcon {
  font-size: 15px;
}

}
