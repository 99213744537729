.terms-container {
    font-family: 'Arial', sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .terms-container h1 {
    padding-top: 120px;
    color: #333;
    text-align: center;
  }
  
  .terms-container h2 {
    color: #FF6D6D;
    margin-top: 20px;
  }
  
  .terms-container p {
    color: #555;
    line-height: 1.6;
    text-align: left;
  }
  
  .terms-container a {
    color: #2980b9;
    text-decoration: none;
  }
  
  .terms-container a:hover {
    text-decoration: underline;
  }
  