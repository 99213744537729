/* .dispensaries-container {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
.product-grid {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Updated to 5 columns */
  gap: 20px;
  padding: 20px;
  max-width: 100%;
}

.add-to-cart {
  background-color: #000;
  font-size: 16px;
  color: #fff;
  border: none;
  padding: 20px 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.15s ease-in;
}
.container {
  max-width: 100%;
  width: 100%;
  margin: 0 auto; /* Center the container */
  padding: 0 20px; /* Optional: Add some padding on the sides */
}


/* .products-title {
  text-align: center;
  font-size: 16px;
  color: #000;
  margin-bottom: 20px;
} */

.products-card {
  justify-content: center;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 100%; /* Updated to fill grid column */
  margin: 0;
  box-sizing: border-box;
  border-radius: 13px;
  border-color: black;
  box-shadow: 2px 2px 3px 6px rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: transform 0.15s ease-in;
  padding: 5px;
}

.products-card:hover {
  transform: translateY(-1px);
  box-shadow: 2px 2px 6px 8px rgba(0, 0, 0, 0.1);
}
.products-card h3 {
  margin: 10px 0;
  font-size: 1em; /* Updated font size to a smaller value */
  /* You can adjust the font size as needed */
}

.products-card img {
  max-width: 100%;
  height: auto;
}

/* Responsive Design Adjustments */
@media screen and (max-width: 1200px) {
  .product-grid {
    grid-template-columns: repeat(4, 1fr); /* Adjust for larger screens */
  }
}

@media screen and (max-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(3, 1fr); /* Adjust for medium screens */
  }
}

@media screen and (max-width: 480px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr); /* Adjust for small screens */
  }

  .products-title {
   font-size: 11px;
   font-weight: 400;
  }
}
