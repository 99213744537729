.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 150px;
}

.text-zone {
    text-align: center;
    margin-bottom: 30px;
}

.title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
}

.sub-title {
    font-size: 1.2rem;
    color: #666;
}

.contact-form {
    background-color: #f9f9f9;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-container {
    list-style-type: none;
    padding: 0;
}

.item {
    margin-bottom: 15px;
}

.input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;
    color: #333;
}

textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 1rem;
    color: #333;
    resize: vertical;
}

.flat-button {
    display: inline-block;
    background-color: #FF6D6D;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.flat-button:hover {
    background-color: #E65555;
}
